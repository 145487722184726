import { extendObservable } from "mobx"

class UserStore{
    constructor(){
        extendObservable(this,{
            loading: false,
            isLoggedIn: false,
            userID: 0,
            username: "",
            email: "",
            favorites: []
        })
    }
}

export default new UserStore();