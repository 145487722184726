import React from 'react';
import SubmitButton from './SubmitButton';
import UserStore from '../stores/UserStore';
import ".././css/dropDownMenu.css";

class FavList extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            favPlaces: []
        }
    }

    componentDidMount() {
        if(UserStore.isLoggedIn) {
            this.fetchFavorites();
        }
    }
    
    fetchFavorites = async() => {
        try {
            let res = await fetch('/getFavorites', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userID: UserStore.userID
                })
            });
            let result = await res.json();
            if(result && result.success) {
                var loadedData = [];
                result.kohteet.forEach(kohde => {
                    fetch('https://www.api.meijanmetsa.com/api/v1/place/' + kohde.placeID)
                    .then(res => res.json())
                    .then((data) => {
                        loadedData.push(data);
                        this.setState({favPlaces: loadedData});
                    })
                    .catch(console.log(this.state.favPlaces))
                });
            } else {
                console.log(result.msg);
            }
        } catch (error) {
            console.log(error);
        }
        
    }

    handleLoadFavoriteToMapClick = (e) => {
        e.preventDefault();
        this.props.loadFavoriteToMap(e.currentTarget.id);
    }

    render() {
        return (
            <div className="dropDownBox">
                <div className='favoriteList'>
                    <h3 className="mt-2">Suosikit</h3>
                    <table className='table table-striped table-hover'>
                        <thead>
                            <tr>
                                <th scope="col">Nimi</th>
                                <th scope="col">Kaupunki</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.favPlaces.map((kohde) => (
                                <tr key={kohde.placeID}>
                                    <td>{kohde.name}</td>
                                    <td>{kohde.city}</td>
                                    <td><SubmitButton id={kohde.placeID} className={'btn btn-primary btn-sm mr-2 mb-2'} text={'Lataa'} onClick={this.handleLoadFavoriteToMapClick}/></td>
                                </tr>
                            ))}
                            
                        </tbody>
                    </table>
                </div>
                <SubmitButton className={'btn btn-primary btn-sm mr-2 mb-2'} text={'< Takaisin'} onClick={this.props.backClick}/>
            </div>
        )
    }
}

export default FavList;