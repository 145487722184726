import React, { Component } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoginForm from './loginMenu';

class Navbar extends Component {
    render() {
        return (
            <nav id="nav-bar" className="navbar navbar-expand-lg navbar-dark bg-primary">
                <a id="nav-bar-a0" className="navbar-brand" href="https://www.meijanmetsa.com">MeijänMetsät</a>
                <button id="toggle-search-btn" type="button" href="#sidebar" data-toggle="collapse" className="btn btn-primary">
                    <FontAwesomeIcon icon={faSearch} />
                    <span> Haku</span>
                </button>
                    <ul id="nav-bar-ul0" className="navbar-nav mr-auto">
                    
                    </ul>
                    <LoginForm loadFavoriteToMap={this.props.loadFavoriteToMap} />
                </nav>
        );
    }
}

export default Navbar;