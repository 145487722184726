import React, { Component } from 'react';

class InputField extends Component {
    render() {
        return (
            <input
                id={this.props.id}
                type={this.props.type}
                className={this.props.className}
                disabled={this.props.disabled}
                value={this.props.value}
                onChange={this.props.onChange}
                placeholder={this.props.placeholder}
            />
        );
    }
}

export default InputField;