import React, { Component } from 'react';
import { Map, TileLayer } from 'react-leaflet';
import { Button } from 'react-bootstrap';
import '.././css/map.css';
import MapMarker from './MapMarker';
import UserLocation from './UserLocation';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const button2 = {
  width: "90px",
	height: "35px",
  top:"12px",
  zIndex:999,
  position: 'absolute', left: '50%',
  transform: 'translate(-50%, -50%)',
};


class MapElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      places: [],
      sijaintisi: [],
      map: null,
      userLocation: [],
      position: [62.241581, 25.758742],
      zoom: 8
    };
  }

  componentDidMount() {
    this.getUserLocation();
    this.map = this.mapInstance.leafletElement
  }

  componentDidUpdate() {
    if (this.props.updateMap) {
      if (this.props.updateMap !== this.state.places) {
        this.setState({
          places: this.props.updateMap
        });
      }
    }
  }

  UserLocation = () => {
    this.props.location(this.state.sijaintisi);
  }

  fetchPosition = (position) => {
    this.setState({ sijaintisi: position})
    // Send location to index.js
    if(this.state.userLocation !== []) {
      this.UserLocation()
    }
  }

  getUserLocation = () => {
    if(navigator.geolocation)
    {
      navigator.geolocation.getCurrentPosition(position => {
        this.setState({
            userLocation: [position.coords.latitude, position.coords.longitude, position.coords.accuracy],
        })
        this.fetchPosition(this.state.userLocation);
      }, error => console.log(error.message), { 
          enableHighAccuracy: true
      });
    }
  }

  totop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  tolocation = (e) => {
    if(this.state.userLocation.length !== 0){
      this.map.flyTo([this.state.userLocation[0],this.state.userLocation[1]], 12)
    }
    else {
     alert("Sijaintia ei saatavilla.")
    }
  }
  

  loadMore = () => {
    this.props.moreItems();
  }

  MapComponent = () => {
    if(this.state.places.length > 0){
      return (    
        <Map ref={e => { this.mapInstance = e }} center={this.state.position} zoom={this.state.zoom} maxZoom={18} minZoom={6}>
          <Button style={button2} onClick={this.totop}> <FontAwesomeIcon icon={faArrowAltCircleUp} /> </Button>
          <Button onClick={this.tolocation} style={{zIndex:999, position:"absolute", left:50,top:17}} ><FontAwesomeIcon icon={faMapMarkedAlt} /> </Button>
          <Button onClick={this.loadMore} style={{zIndex:999, position:"absolute", left:110,top:17}} >Lataa lisää</Button>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
          />
          {this.state.places.map((kohde) => (
            <MapMarker key={"marker" + kohde.placeID} content={kohde}/>
          ))}
          <UserLocation location={this.state.sijaintisi}/>
        </Map>
      )
    }
    else {
      return (
        <Map ref={e => { this.mapInstance = e }} center={this.state.position} zoom={7} maxZoom={18} minZoom={6}>
          <Button style={button2} onClick={this.totop} > <FontAwesomeIcon icon={faArrowAltCircleUp} /> </Button>
          <Button onClick={this.tolocation } style={{zIndex:999, position:"absolute", left:50,top:17}} ><FontAwesomeIcon icon={faMapMarkedAlt} /> </Button>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            />
            <UserLocation location={this.state.sijaintisi}/>
        </Map>
      )
    }
  }

  render() {
    return (
        <div id="mapid" className="h-100 w-100">
          <this.MapComponent />
        </div>
    )
  }
}

export default MapElement;