import React, { Component } from 'react';
import L from 'leaflet';
import { Marker, Popup, Circle } from 'react-leaflet';

class UserLocation extends Component {
    render() {
        if(this.props.location[0] !== undefined && this.props.location[1] !== undefined) {
            let lat = this.props.location[0];
            let lon = this.props.location[1];
            let accuracy = this.props.location[2];
            return (
                <div>
                    <Circle center={[lat, lon]} radius={accuracy / 2}/>
                    <Marker position={[lat, lon]} icon={
                        new L.Icon({
                        iconUrl: require('../img/red-icon.png'),
                        iconSize: new L.Point(20,30)
                        })
                    }>
                    <Popup>
                    <table>
                        <tbody>
                        <tr><td className="PopupTitle"><b>Sijaintisi</b></td></tr>
                        <tr><td>{lat.toFixed(4)}, {lon.toFixed(4)}</td></tr>
                        <tr><td>Tarkkuus: {accuracy} m</td></tr>
                        </tbody>
                    </table>
                    </Popup>
                    </Marker>
                </div>
            )
        }else {
            return null
        }
    }
}

export default UserLocation;