import React, { Component } from 'react';
import SubmitButton from './SubmitButton';
import UserStore from '../stores/UserStore';
import ChangePassword from './changePasswodForm';
import FavList from './FavList';
import ".././css/dropDownMenu.css";

class UserSettings extends Component {

    constructor(props){
        super(props);
        this.state = {
            windowMode: 0
        }
    }

    handleEditPasswordClick = (e) => {
        e.preventDefault();
        this.setState({
            windowMode: 1
        });
        console.log(this.state);
    }

    handleBackClick = (e) => {
        e.preventDefault();
        this.setState({
            windowMode: 0
        });
    }

    handleFavClick = (e) => {
        e.preventDefault();
        this.setState({
            windowMode: 2
        });
    }

    render() {
        if(this.state.windowMode === 1) {
            return (
                <ChangePassword 
                    backClick={this.handleBackClick}
                    infoType={this.props.infoType}
                    infoBody={this.props.infoBody}
                    inputPwClass={this.props.inputPwClass}
                    inputNewPwClass={this.props.inputNewPwClass}
                    inputConfirmPwClass={this.props.inputConfirmPwClass}
                    handleOnPasswordChange={this.props.handleOnPasswordChange}
                    handleOnNewPasswordChange={this.props.handleOnNewPasswordChange}
                    handleOnPasswordConfirmationChange={this.props.handleOnPasswordConfirmationChange}
                    handleEditPwClick={this.props.handleEditPwClick}
                />
            );
        } else if (this.state.windowMode === 0) {
            return (
                <div className="dropDownBox">
                    <h3 className="mt-2">Käyttäjäasetukset</h3>
                    <table className='table table-striped'>
                        <tbody>
                            <tr>
                                <th scope="row">Käyttäjänimi</th>
                                <td>{UserStore.username}</td>
                            </tr>
                            <tr>
                                <th scope="row">Sähköposti</th>
                                <td>{this.props.email}</td>
                            </tr>
                        </tbody>
                    </table>
                    <SubmitButton className={'btn btn-primary btn-sm mr-2 mb-2'} text={'Vaihda salasana'} onClick={this.handleEditPasswordClick}/>
                    <SubmitButton className={'btn btn-danger btn-sm mr-2 mb-2'} text={'Poista tili'} onClick={this.props.removeUser}/>
                    <SubmitButton id={'logout-button'} className={'btn btn-primary btn-sm mr-2 mb-2'} text={'Kirjaudu Ulos'} onClick={this.props.logout} />
                    <SubmitButton id={'favorites-lista'} className={'btn btn-primary btn-sm mr-2 mb-2'} text={'Suosikit'} onClick={this.handleFavClick} />
                </div>
            )
        } else {
            return(
                <FavList backClick={this.handleBackClick} loadFavoriteToMap={this.props.loadFavoriteToMap} />
            )            
        }
        
    }
}

export default UserSettings;