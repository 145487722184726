import React, { Component } from 'react';
import L from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import '.././css/map.css';
import UserStore    from '../stores/UserStore';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Rating } from '@material-ui/lab';

class MapMarker extends Component {
    constructor(props) {
        super(props);
        this.weather = {};
        this.state = {
            loaded: false,
            IsFavorite: null,
            ratingLoaded: false
        }
        this.content = this.props.content;
        this.placeID = this.props.content.placeID;
        this.rating = 0;
        this.ratingCount = 0;
    }

    fetchWeather = (lat, lon, marker) => {
        // Load Favorites
        if(UserStore.userID !== 0) {
            this.FavoriteStatus();
        }
        // Load Ratings
        if(this.placeID !== 0) {
            this.GetRating();
        }
        // Check if data has been already fetched
        if(!(marker in this.weather)) {
            fetch('https://www.api.meijanmetsa.com/api/v1/wheather?lat=' + lat + '&lon=' + lon)
            .then(res => res.json())
            .then((data) => {
                this.weather[marker] = data;
                this.setState({loaded: true })
            })
            .catch((error) => {
               // console.log("WeatherAPI: ", error);
            });
        }
    }

    iconSize = (iconName) => {
        var icon = iconName.toLowerCase().split('/')[0]
        if (icon === 'kuntokeskus' || icon === 'kuntosali' || icon === 'tekojääkenttä') {
          return (20, 30);
        }
        else if (icon === 'ulkoilumaja' || icon === 'kampailulajien sali' || icon === 'koripallokenttä'|| icon === 'lentopallo') {
          return (30, 30);
        }
        else if (icon === 'beachvolleykenttä') {
          return (15, 30);
        }
        else {
          return (15, 30);
        }
    }

    UpdateFavorite = async() => {
        if(this.placeID !== 0 && UserStore.userID !== 0) {
            let res = await fetch('/favorite', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    IsFavorite: this.state.IsFavorite,
                    userID: UserStore.userID,
                    placeID: this.placeID
                })
            });
            let result = await res.json();
            if(result && result.success) {
                this.setState({IsFavorite: result.IsFavorite});
            }
        }
    }

    FavoriteStatus = async() => {
        if(this.placeID !== 0 && UserStore.userID !== 0) {
            let res = await fetch('/favoriteStatus', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userID: UserStore.userID,
                    placeID: this.placeID
                })
            });
            let result = await res.json();
            if(result && result.success) {
                this.setState({IsFavorite: result.IsFavorite});
            }
        }
    }

    AddRating = async(value) => {
        if(value >= 0 || value <= 5) {
            let res = await fetch('/rating/add', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userID: UserStore.userID,
                    placeID: this.placeID,
                    rating: value
                })
            });
            let result = await res.json();
            if(result && result.success) {
                alert("Arvostelu lisätty onnistuneesti");
                this.GetRating();
            }else {
                if(result.ratingExists) {
                    alert("Olet antanut jo arvostelun tälle kohteelle");
                }
            }
        }
    }

    GetRating = async() => {
        let res = await fetch('/rating', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                placeID: this.placeID
            })
        });
        let result = await res.json();
        if(result && result.success) {
            this.rating = result.rating;
            this.ratingCount = result.count;
            this.setState({ratingLoaded: true});
        }
    }

    handleRatingChange = (e, value) => {
        console.log("Rated", value);
        if(UserStore.userID !== 0) {
            this.AddRating(value);
        }else {
            alert("Sinun täytyy kirjautua mikäli haluat antaa arvostelun");
        }
    }

    handleFavoriteClick = (e) => {
        e.preventDefault();
        this.UpdateFavorite();
    }

    render() {
        return (
            <Marker key={this.content.placeID} onClick={() => this.fetchWeather(this.content.lat, this.content.lon, this.content.placeID)} position={[this.content.lat, this.content.lon]} icon={
                new L.Icon({
                   iconUrl: this.content.icon === '' ? require('../img/green-icon.png'): require('../img/' + this.content.icon.toLowerCase() + '.png'), 
                    iconSize: new L.Point(this.iconSize(this.content.icon)),
                })
            }>
                <Popup key={"popup" + this.content.placeID} style={{maxWidth: "auto"}} >
                    <table id="content">
                        <tbody>
                            <tr>
                                <td className="PopupTitle" colSpan="3"><b>{this.content.name}</b></td>
                            </tr>
                            <LoginItems 
                                Favorite={this.handleFavoriteClick} 
                                IsFavorite={this.state.IsFavorite} 
                                Rating={this.handleRatingChange}
                                RatingLoaded={this.state.ratingLoaded}
                                RatingValue={this.rating}
                                RatingCount={this.ratingCount}
                            />
                            <TableTD item={this.content.typeName}/>
                            <TableTD item={this.content.admin}/>
                            <TableTD item={this.content.address}/>
                            <TableTD item={this.content.cityPart} item2={this.content.city}/>
                            <TableTD item={this.content.phone}/>
                            <TableTD item={this.content.email}/>
                            <TableTD link={this.content.www}/> 
                        </tbody>
                    </table>
                    <br></br>
                    <Weather loaded={this.state.loaded} data={this.weather[this.content.placeID]}/>
                    <p id="Updated">Päivitetty: {this.content.modified.split('.')[0]}</p>
                </Popup>
            </Marker>
        )
    }
}

class LoginItems extends Component {
    render() {
        let rating = 0;
        let ratingCount = 0;

        // Update Values when rating loaded
        if(this.props.RatingLoaded) {
            rating = this.props.RatingValue;
            ratingCount = this.props.RatingCount;
        }

        // Check if user is logged in
        if(UserStore.isLoggedIn) {
            let starColor = "black";
            let title = "Lisää suosikkeihin";
            if(this.props.IsFavorite) {
                starColor = "orange";
                title = "Poista suosikeista";
            }
            return (
                <tr>
                    <td>
                        <button type="button" className="btn btn-default btn-lg iconButton" title={title} onClick={this.props.Favorite}>
                            <FontAwesomeIcon icon={faStar} color={starColor}/>
                        </button>
                    </td>
                    <td className="ratingCell">
                        <Rating name="rating" onChange={this.props.Rating} value={rating}/>
                    </td>
                    <td className="ratingCell">
                        <span>{rating} ({ratingCount} kpl)</span>
                    </td>
                </tr>
            )
        }else {
            return (
                <tr>
                    <td></td>
                    <td className="ratingCell">
                        <Rating name="rating" onChange={this.props.Rating} value={rating}/>
                    </td>
                    <td className="ratingCell">
                        <span>{rating} ({ratingCount} kpl)</span>
                    </td>
                </tr>
            )
        }
    }
}

class TableTD extends Component {
    render() {
        if(this.props.item !== undefined && this.props.item !== "" && this.props.item2 !== undefined && this.props.item2 !== "") {
            return (
                <tr>
                <td colSpan="3">{this.props.item}, {this.props.item2}</td>
                </tr>
            )
        }else if(this.props.item !== undefined && this.props.item !== "") {
            return (
                <tr>
                <td colSpan="3">{this.props.item}</td>
                </tr>
            )
        }else if(this.props.item2 !== undefined && this.props.item2 !== "") {
            return (
                <tr>
                <td colSpan="3">{this.props.item2}</td>
                </tr>
            )
        }else if(this.props.link !== undefined && this.props.link !== "") {
            return (
                <tr>
                <td colSpan="3"><a href={this.props.link}>{this.props.link}</a></td>
                </tr>
            )
        }else {
            return null
        }
    }
}

class Weather extends Component {
    render() {
        if(this.props.loaded) {
            return (
                <table id="weather">
                    <thead>
                        <tr>
                            <th colSpan="2">Kohteen sää</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>
                            <img id="weatherIcon" alt="Weather Icon" src={"https://openweathermap.org/img/wn/" + this.props.data.icon + "@2x.png"} width="64" height="64" title={this.props.data.desc}></img>
                        </td>
                        <td>
                            <b>{this.props.data.temp} °C</b><br></br>
                            <b>{this.props.data.wind} m/s</b>
                        </td>
                        </tr>
                    </tbody>
                </table>
            )
        }else {
            return (
                <table id="weather">
                    <thead>
                        <tr>
                            <th colSpan="2">Kohteen sää</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Säätietoja ei saatavilla</td>
                        </tr>
                    </tbody>
                </table>
            )
        }
    }
}

export default MapMarker;