import React, { Component } from 'react';

class SubmitButton extends Component {
    render() {
        return (
            <button
                id={this.props.id}
                type="submit"
                className={this.props.className}
                disabled={this.props.disabled}
                onClick={this.props.onClick}
            >
                {this.props.text}
            </button>
        );
    }
}

export default SubmitButton;