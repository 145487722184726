import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './css/main.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'leaflet/dist/leaflet.css';
import MapElement from './components/map';
import Sidebar from './components/sidebar';
import Navbar from './components/navbar';
import LoadingOverlay from 'react-loading-overlay';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            places: [],
            weather: [],
            loading: false,
            userLocation: [],
        };
        this.loadedData = [];
        this.currentPage = 0;
        this.searchParams = [];
    }

    searchAll = (searchWord, searchNearMe, paikkakunta, palvelut) => {
        if(searchWord !== "" || searchNearMe === true || paikkakunta !== "" || palvelut !== "") {
            if(this.state.userLocation.length !== 0)
            {
                this.loadedData = [];
                this.currentPage = 0;
                if(palvelut !== "") {
                    searchWord = palvelut;
                }
                if(paikkakunta !== "") {
                    searchNearMe = paikkakunta;   
                }
                this.GetData(searchWord, searchNearMe, 1);
                this.searchParams = [searchWord, searchNearMe];
            }
            else if(searchWord !== "" || palvelut !== "")
            {
                this.loadedData = [];
                this.currentPage = 0;
                if(palvelut !== "") {
                    searchWord = palvelut;
                }
                if(paikkakunta !== "") {
                     searchNearMe = paikkakunta;   
                }
                this.GetData(searchWord, searchNearMe, 1);
                this.searchParams = [searchWord, searchNearMe];
            }
            else{
                alert("Hakua sijaintisi läheisyydestä ei voitu tehdä. Laitathan sijainti palvelut päälle.");
            }
        }else
        {
            alert("Haku kenttä on tyhjä. Hakua ei suoritettu.");
        }
    }
    
    GetData(searchWord, searchNearMe, page) {
        let location = "";
        let pageString = "";
        let search = "?search=";
        // If SearchNearMe is enabled send user location with request
        if(searchNearMe === true && this.state.userLocation.length !== 0) {
            location += "lat=" + parseFloat(this.state.userLocation[0]).toFixed(4) + "&lon=" + parseFloat(this.state.userLocation[1]).toFixed(4);
        }else if(searchNearMe === true && this.state.userLocation.length === 0) {
            alert("Hakua sijaintisi läheisyydestä ei voitu tehdä. Etsitään ilman sijaintia. Laitathan sijainti palvelut päälle.");
        }else if(searchNearMe !== false) {
            if(searchWord === "") {
                searchWord = searchNearMe;
            }else {
                searchWord += ", " + searchNearMe;
            }
        }
        if(searchWord === "") {
            search = "?";
        }else {
            searchWord += "&";
        }
        if(page > 1) {
            pageString += "&page=" + page;
        }
        this.setState({loading: true});
        fetch('https://www.api.meijanmetsa.com/api/v1/places' + search + searchWord  + location + pageString)
        .then(res => res.json())
        .then((data) => {
            console.log(data);
            if(data.length === 0) {
                alert("Tuloksia ei enää löytynyt");
            }else {
                this.currentPage += 1;
            }
            console.log("Page", page, "loaded");
            data.forEach(row => {
                this.loadedData.push(row);
                this.setState({ places: this.loadedData});
            });
            this.setState({loading: false});
            var latAvg = 0;
            var lonAvg = 0;
            var zeros = 0;
            this.loadedData.forEach(i => {
                if(i.lat === 0 && i.lon === 0){
                    zeros += 1;
                } else {
                    latAvg += parseFloat(i.lat);
                    lonAvg += parseFloat(i.lon);
                }
            });
            latAvg = latAvg / (this.loadedData.length - zeros);
            lonAvg = lonAvg / (this.loadedData.length - zeros);

            console.log(latAvg);

            this.mapElement.map.flyTo([latAvg, lonAvg], 10);
        })
        .catch(console.log(this.state.places))
    }

    LoadMoreData = () => {
        this.GetData(this.searchParams[0], this.searchParams[1], this.currentPage + 1);
    }

    GetUserLocation = (location) => {
        if(location !== []) {
            this.setState({ userLocation: location});
            this.searchAll("", true, "", "");
        }
    }

    loadFavoriteToMap = (placeID) => {
        this.loadedData = [];
        this.setState({loading: true});
        fetch('https://www.api.meijanmetsa.com/api/v1/place/' + placeID)
        .then(res => res.json())
        .then((data) => {
            console.log(data);
            this.loadedData.push(data);
            this.mapElement.map.flyTo([data.lat, data.lon], 15);
            this.setState({places: this.loadedData});
            this.setState({loading: false});
        })
        .catch(console.log(this.state.places));
        
    }

    render() {
        return (
            <div id="main-container" className="container-fluid h-100 p-0">
                <Navbar loadFavoriteToMap={this.loadFavoriteToMap} />
                <div id="container0" className="row h-100 w-100 m-0 wrapper">
                    <Sidebar search={this.searchAll}/>
                    <div id="container02" className="col h-100 w-100 p-0">              
                        <MapElement ref={(cd) => this.mapElement = cd} updateMap={this.state.places} location={this.GetUserLocation} moreItems={this.LoadMoreData}/>
                        <LoadingOverlay active={this.state.loading} spinner text='Loading your places...'/>
                    </div>
                </div>
            </div>
        );
    }
}

ReactDOM.render(<App />, document.getElementById('root'));