import React from 'react';
import SubmitButton from './SubmitButton';
import InputField   from './InputField';
import ".././css/dropDownMenu.css";

class ChangePassword extends React.Component {
    render() {
        return (
            <div className="dropDownBox">
                <h2 className="otsikko">Vaihda salasana</h2>
                <div className={this.props.infoType} role="alert">
                    {this.props.infoBody}
                </div>
                <form id="dropdown-form0">
                    <div id="dropdown-div1" className="form-group">
                        <label id="dropdown-label0" htmlFor="exampleInputEmail1">Nykyinen salasana</label>
                        <InputField id={"username-input"} type={"password"} className={this.props.inputPwClass} onChange={this.props.handleOnPasswordChange} aria-describedby="emailHelp" placeholder={"Nykyinen salasana"} />
                    </div>
                    <div id="dropdown-div2" className="form-group">
                        <label id="dropdown-label1" htmlFor="exampleInputPassword1">Uusi salasana</label>
                        <InputField id={"password-input"} type={"password"} className={this.props.inputNewPwClass} onChange={this.props.handleOnNewPasswordChange} placeholder={"Uusi salasana"} />
                    </div>
                    <div id="dropdown-div3" className="form-group">
                        <label id="dropdown-label2" htmlFor="exampleInputPassword1">Vahvista uusi salasana</label>
                        <InputField id={"password-input0"} type={"password"} className={this.props.inputConfirmPwClass} onChange={this.props.handleOnPasswordConfirmationChange} placeholder={"Vahvista uusi Salasana"} />
                    </div>
                    <div id="dropdown-div3" className="row">
                        <SubmitButton id={'login-button'} text={'< Takaisin'} className={'ml-3 btn btn-primary'} onClick={this.props.backClick} />
                        <SubmitButton id={'dropdown-button2'} text={'Lähetä'} className={'ml-2 btn btn-primary'} onClick={this.props.handleEditPwClick} />
                    </div>
                </form>
            </div>
        )
    }
}

export default ChangePassword;