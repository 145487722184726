import React        from 'react';
import { observer } from 'mobx-react';
import UserStore    from '../stores/UserStore';
import UserSettings from './UserSettings'
import SubmitButton from './SubmitButton';
import InputField   from './InputField';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ".././css/dropDownMenu.css";

class LoginForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            regClick: false,
            username: '',
            password: '',
            password_confirm: '',
            password_new: '',
            email: '',
            buttonDisabled: false,
            inputUsernameClass: "form-control",
            inputPwClass: "form-control",
            inputNewPwClass: "form-control",
            inputConfirmPwClass: "form-control",
            inputEmailClass: "form-control",
            usernameCheck: false,
            pwCheck: false,
            info: {
                body: '',
                type: '',
                alerts: {
                    success: "alert alert-success",
                    danger: "alert alert-danger",
                    primary: "alert alert-primary"
                },
                inputs: {
                    default: "form-control",
                    valid: "form-control is-valid",
                    invalid: "form-control is-invalid"
                }
            }
        }
    }

    componentDidMount = async() => {
        try {
            let res = await fetch('/isLoggedIn', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            let result = await res.json();

            if(result && result.success){
                UserStore.loading = false;
                UserStore.isLoggedIn = true;
                UserStore.username = result.username;
                UserStore.email = result.email;
                UserStore.userID = result.id;
            } else{
                UserStore.loading = false;
                UserStore.isLoggedIn = false;
            }
        }
        catch(e){
            UserStore.loading = false;
            UserStore.isLoggedIn = false;
        }
    }

    doLogout = async(isMsg) => {
        try {
            let res = await fetch('/logout', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            let result = await res.json();

            if(result && result.success){
                UserStore.isLoggedIn = false;
                UserStore.username = "";
                UserStore.email = "";
                UserStore.userID = 0;
                this.resetForm(isMsg);
            }
        }

        catch(e){
            console.log(e);
        }
    }

    doLogin = async() => {
        if(!this.state.username){
            let infoCopy = this.state.info;
            infoCopy.body = "Syötä käyttäjänimi";
            infoCopy.type = this.state.info.alerts.danger;
            this.setState({
                info: infoCopy,
                inputUsernameClass: this.state.info.inputs.invalid
            });
            return;
        } else {
            this.setState({
                inputUsernameClass: this.state.info.inputs.valid,
                usernameCheck: true
            });

            if(!this.state.password){
                let infoCopy = this.state.info;
                infoCopy.body = "Syötä salasana";
                infoCopy.type = this.state.info.alerts.danger;
                this.setState({
                    info: infoCopy,
                    inputPwClass: this.state.info.inputs.invalid
                });
                return;
            } else {
                this.setState({
                    inputPwClass: this.state.info.inputs.valid,
                    pwCheck: true
                });

                try {
                    let res = await fetch('/login', {
                        method: 'post',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            username: this.state.username,
                            password: this.state.password
                        })
                    });
        
                    let result = await res.json();
                    if (result && result.success) {
                        UserStore.isLoggedIn = true;
                        UserStore.username = result.username;
                        UserStore.email = result.email;
                        UserStore.userID = result.id;
                        let infoCopy = this.state.info;
                        infoCopy.body = '';
                        infoCopy.type = '';
                        this.setState({
                            info: infoCopy,
                            inputPwClass: this.state.info.inputs.invalid
                        });
                    }
        
                    else if (result && result.success === false){
                        let infoCopy = this.state.info;
                        infoCopy.body = result.msg;
                        infoCopy.type = this.state.info.alerts.danger;
                        this.setState({
                            info: infoCopy,
                            inputPwClass: this.state.info.inputs.invalid
                        });
                        return;
                        //this.resetForm(false);
                        //alert(result.msg)
                    }
                }
                catch(e){
                    console.log(e);
                    this.resetForm(false);
                }
            }
        }
    }

    doRegister = async() => {
        if(!this.state.username){
            let infoCopy = this.state.info;
            infoCopy.body = "Syötä käyttäjänimi";
            infoCopy.type = this.state.info.alerts.danger;
            this.setState({
                info: infoCopy,
                inputUsernameClass: this.state.info.inputs.invalid
            });
            return;
        } else {
            this.setState({
                inputUsernameClass: this.state.info.inputs.valid,
                usernameCheck: true
            });
        }

        if(!this.state.email){
            let infoCopy = this.state.info;
            infoCopy.body = "Syötä sähköposti";
            infoCopy.type = this.state.info.alerts.danger;
            this.setState({
                info: infoCopy,
                inputEmailClass: this.state.info.inputs.invalid
            });
            return;
        } else {
            this.setState({
                inputEmailClass: this.state.info.inputs.valid
            });
        }

        if(!this.state.password){
            let infoCopy = this.state.info;
            infoCopy.body = "Syötä salasana";
            infoCopy.type = this.state.info.alerts.danger;
            this.setState({
                info: infoCopy,
                inputPwClass: this.state.info.inputs.invalid
            });
            return;
        } else {
            this.setState({
                inputPwClass: this.state.info.inputs.valid,
                pwCheck: true
            });
        }

        if(this.state.password !== this.state.password_confirm){
            let infoCopy = this.state.info;
            infoCopy.body = "Salasanat eivät täsmä, kokeile uudestaan";
            infoCopy.type = this.state.info.alerts.danger;
            this.setState({
                info: infoCopy,
                inputPwClass: this.state.info.inputs.invalid
            });
            return;
        } else {
            this.setState({
                inputPwClass: this.state.info.inputs.valid
            });
            try {
                let res = await fetch('/register', {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        username: this.state.username,
                        password: this.state.password,
                        email: this.state.email
                    })
                });
    
                let result = await res.json();
                if (result && result.success) {
                    let infoCopy = this.state.info;
                    infoCopy.body = result.msg;
                    infoCopy.type = this.state.info.alerts.success;
                    this.setState({
                        info: infoCopy,
                        regClick: false,
                        pwCheck: false
                    });
                    this.resetForm(true);
                }
    
                else if (result && result.success === false){
                    let infoCopy = this.state.info;
                    infoCopy.body = result.msg;
                    infoCopy.type = this.state.info.alerts.danger;
                    if(result.isUsername){
                        this.setState({
                            info: infoCopy,
                            inputUsernameClass: this.state.info.inputs.invalid
                        });
                    }
                    else if (result.isEmail) {
                        this.setState({
                            info: infoCopy,
                            inputEmailClass: this.state.info.inputs.invalid
                        });
                    } else {
                        alert("Wut?");
                    }
                    
                }
            }
    
            catch(e){
                console.log(e);
                this.resetForm(false);
            }
        }
        
    }

    removeUser = async() => {
        try {
            let res = await fetch('/removeUser', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            let result = await res.json();

            if(result && result.success){
                let infoCopy = this.state.info;
                infoCopy.body = result.msg;
                infoCopy.type = this.state.info.alerts.primary;
                this.setState({
                    info: infoCopy,
                    regClick: false
                });
                this.doLogout(true);
                //this.resetForm(false);
            } 
            else if (result && !result.success){
                console.log(result.msg);
            }
        }

        catch(e){
            console.log(e);
        }
    }

    editPassword = async() => {
            if(!this.state.password){
                let infoCopy = this.state.info;
                infoCopy.body = "Syötä salasana";
                infoCopy.type = this.state.info.alerts.danger;
                this.setState({
                    info: infoCopy,
                    inputPwClass: this.state.info.inputs.invalid,
                    pwCheck: false
                });
                return;
            } else {
                let infoCopy = this.state.info;
                infoCopy.body = '';
                infoCopy.type = '';
                this.setState({
                    info: infoCopy,
                    inputPwClass: this.state.info.inputs.valid,
                    pwCheck: true
                });
            }
            
            if (!this.state.password_new) {
                let infoCopy = this.state.info;
                infoCopy.body = "Syötä uusi salasana";
                infoCopy.type = this.state.info.alerts.danger;
                this.setState({
                    info: infoCopy,
                    inputNewPwClass: this.state.info.inputs.invalid,
                    pwCheck: false
                });
                return;
            } else {
                let infoCopy = this.state.info;
                infoCopy.body = '';
                infoCopy.type = '';
                this.setState({
                    info: infoCopy,
                    inputNewPwClass: this.state.info.inputs.valid,
                    pwCheck: true
                });
            }
            
            if (!this.state.password_confirm) {
                let infoCopy = this.state.info;
                infoCopy.body = "Vahvista salasana";
                infoCopy.type = this.state.info.alerts.danger;
                this.setState({
                    info: infoCopy,
                    inputConfirmPwClass: this.state.info.inputs.invalid,
                    pwCheck: false
                });
                return;
            } else {
                let infoCopy = this.state.info;
                infoCopy.body = '';
                infoCopy.type = '';
                this.setState({
                    info: infoCopy,
                    inputConfirmPwClass: this.state.info.inputs.valid,
                    pwCheck: true
                });
            }
            
            if (this.state.password_new !== this.state.password_confirm) {
                let infoCopy = this.state.info;
                infoCopy.body = "Salasanat eivät täsmä, kokeile uudestaan";
                infoCopy.type = this.state.info.alerts.danger;
                this.setState({
                    info: infoCopy,
                    inputNewPwClass: this.state.info.inputs.invalid,
                    inputConfirmPwClass: this.state.info.inputs.invalid,
                    pwCheck: false
                });
                return;
            } else {
                let infoCopy = this.state.info;
                infoCopy.body = '';
                infoCopy.type = '';
                this.setState({
                    info: infoCopy,
                    inputNewPwClass: this.state.info.inputs.valid,
                    inputConfirmPwClass: this.state.info.inputs.valid,
                    pwCheck: true
                });
            }
            
            if (this.state.password === this.state.password_new) {
                let infoCopy = this.state.info;
                infoCopy.body = "Uusi salasana ei voi olla sama kun vanha";
                infoCopy.type = this.state.info.alerts.danger;
                this.setState({
                    info: infoCopy,
                    inputPwClass: this.state.info.inputs.invalid,
                    inputNewPwClass: this.state.info.inputs.invalid,
                    pwCheck: false
                });
                return;
            } else {
                let infoCopy = this.state.info;
                infoCopy.body = '';
                infoCopy.type = '';
                this.setState({
                    info: infoCopy,
                    inputPwClass: this.state.info.inputs.valid,
                    inputNewPwClass: this.state.info.inputs.valid,
                    pwCheck: true
                });

                try {
                    let res = await fetch('/editPassword', {
                        method: 'post',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            password: this.state.password,
                            password_new: this.state.password_new
                        })
                    });
        
                    let result = await res.json();
        
                    if(result && result.success){
                        
                        let infoCopy = this.state.info;
                        infoCopy.body = result.msg;
                        infoCopy.type = this.state.info.alerts.primary;
                        this.setState({
                            info: infoCopy,
                            regClick: false
                        });
                        this.doLogout(true);
                        //this.resetForm(true);
                    }
                    else if(result && !result.success){
                        console.log(result.msg);
                        let infoCopy = this.state.info;
                        infoCopy.body = result.msg;
                        infoCopy.type = this.state.info.alerts.danger;
                        this.setState({
                            info: infoCopy
                        });
                        return;
                    }
                }
        
                catch(e){
                    console.log(e);
                }
            }
    }

    setInputValue = (property, val) => {
        val = val.trim();
        if(val.length > 50){
            return;
        }
        this.setState({
            [property]: val
        })
    }

    handleSubmitClick = (e) => {
        e.preventDefault();
        this.doRegister();
    }

    handleLoginClick = (e) => {
        e.preventDefault();
        this.doLogin();
    }

    handleRegClick = (e) => {
        e.preventDefault();
        this.resetForm(false);
        this.setState({
            regClick: true
        });
        
    }

    handleBackClick = (e) => {
        e.preventDefault();
        this.setState({
            regClick: false
        });
    }

    handleRemoveUserClick = (e) => {
        e.preventDefault();
        var conf = window.confirm("Haluatko varmasti poistaa MeijanMetsat tilin?");
        if(conf){
            this.removeUser();
        } else {
            console.log("Olet parempi ihminen!");
        }
    }

    handleEditPwClick = (e) => {
        e.preventDefault();
        this.editPassword();
    }

    handleOnUsernameChange = (e) => {
        this.setInputValue('username', e.target.value);
    }

    handleOnEmailChange = (e) => {
        this.setInputValue('email', e.target.value);
    }

    handleOnPasswordChange = (e) => {
        this.setInputValue('password', e.target.value);
    }

    handleOnNewPasswordChange = (e) => {
        this.setInputValue('password_new', e.target.value);
    }

    handleOnPasswordConfirmationChange = (e) => {
        this.setInputValue('password_confirm', e.target.value);
    }

    resetForm = (isReg) => {
        let infoCopy = this.state.info;
        if(!isReg){
            infoCopy.body = '';
            infoCopy.type = '';
        }
        this.setState({
            username: '',
            password: '',
            email: '',
            password_confirm: '',
            password_new: '',
            buttonDisabled: false,
            usernameCheck: false,
            pwCheck: false,
            inputUsernameClass: this.state.info.inputs.default,
            inputPwClass: this.state.info.inputs.default,
            info: infoCopy
        })
    }

    usernameCheck = () => {
        if(!this.state.username){
            let infoCopy = this.state.info;
            infoCopy.body = "Syötä käyttäjänimi";
            infoCopy.type = this.state.info.alerts.danger;
            this.setState({
                info: infoCopy,
                inputUsernameClass: this.state.info.inputs.invalid
            });
            return;
        } else {
            let infoCopy = this.state.info;
            infoCopy.body = '';
            infoCopy.type = '';
            this.setState({
                info: infoCopy,
                inputUsernameClass: this.state.info.inputs.valid,
                usernameCheck: true
            });
        }
    }


    render() {
        if(UserStore.loading){
            return (
                <div id="nav-bar-div0" className="dropdown">
                    <button id="nav-bar-button0" className="btn btn-primary dropdown-toggle" type="button">Ladataan...
                    <span id="nav-bar-span0" className="caret"></span></button>
                    
                </div>
            );
        }
        else {
            if(UserStore.isLoggedIn){
                return (
                    <div id="nav-bar-div0" className="dropdown">
                        <button id="nav-bar-button0" className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
                        <FontAwesomeIcon icon={faUser} />
                        <span> {UserStore.username}</span>
                        <span id="nav-bar-span0" className="caret"></span></button>
                        <div id="dropdown-div0" className="p-2 dropdown-menu dropdown-menu-right" style={{"minWidth":"350px"}}>
                            <UserSettings 
                                email={UserStore.email}
                                logout={this.doLogout}
                                removeUser={this.handleRemoveUserClick}
                                infoType={this.state.info.type}
                                infoBody={this.state.info.body}
                                inputPwClass={this.state.inputPwClass}
                                inputNewPwClass={this.state.inputNewPwClass}
                                inputConfirmPwClass={this.state.inputConfirmPwClass}
                                handleOnPasswordChange={this.handleOnPasswordChange}
                                handleOnNewPasswordChange={this.handleOnNewPasswordChange}
                                handleOnPasswordConfirmationChange={this.handleOnPasswordConfirmationChange}
                                handleEditPwClick={this.handleEditPwClick}
                                loadFavoriteToMap={this.props.loadFavoriteToMap}
                            />
                        </div>
                        
                    </div>
                );
            }else{
                if(!this.state.regClick){
                    return (
                        <div id="nav-bar-div0" className="dropdown">
                            <button id="nav-bar-button0" className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">Kirjaudu
                            <span id="nav-bar-span0" className="caret"></span></button>
                            <div id="dropdown-div0" className="p-2 dropdown-menu dropdown-menu-right" style={{"minWidth":"350px"}}>
                                <div className="dropDownBox">
                                    <h2 className="otsikko">Kirjaudu palveluun</h2>
                                    <div className={this.state.info.type} role="alert">
                                        {this.state.info.body}
                                    </div>
                                    <form id="dropdown-form0">
                                        <div id="dropdown-div1" className="form-group">
                                            <label id="dropdown-label0" htmlFor="exampleInputEmail1">Käyttäjänimi</label>
                                            <InputField id={"username-input"} type={"text"} value={this.state.username ? this.state.username : ''} className={this.state.inputUsernameClass} onChange={this.handleOnUsernameChange} aria-describedby="emailHelp" placeholder={"Syötä käyttäjänimi"} />
                                        </div>
                                        <div id="dropdown-div2" className="form-group">
                                            <label id="dropdown-label1" htmlFor="exampleInputPassword1">Salasana</label>
                                            <InputField id={"password-input"} type={"password"} value={this.state.password ? this.state.password : ''} className={this.state.inputPwClass} onChange={this.handleOnPasswordChange} placeholder={"Salasana"} />
                                        </div>
                                        <div id="dropdown-div3" className="row">
                                            <SubmitButton id={'login-button'} text={'Kirjaudu'} className={'ml-3 btn btn-primary'} onClick={this.handleLoginClick} disabled={this.state.buttonDisabled}/>
                                            <SubmitButton id={'dropdown-button2'} text={'Rekiströidy'} className={'ml-2 btn btn-primary'} onClick={this.handleRegClick}/>
                                            <SubmitButton id={'dropdown-button1'} text={'Facebook'} className={'ml-2 btn btn-secondary'}/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    );
                }
                else {
                    return (
                        <div id="nav-bar-div0" className="dropdown">
                            <button id="nav-bar-button0" className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">Kirjaudu
                            <span id="nav-bar-span0" className="caret"></span></button>
                            <div id="dropdown-div0" className="p-2 dropdown-menu dropdown-menu-right" style={{"minWidth":"350px"}}>
                                <div className="dropDownBox">
                                    <h2>Rekiströidy palveluun</h2>
                                    <div className={this.state.info.type} role="alert">
                                        {this.state.info.body}
                                    </div>
                                    <form id="dropdown-form0">
                                        <div id="dropdown-div1" className="form-group">
                                            <label id="dropdown-label0" htmlFor="exampleInputEmail1">Käyttäjänimi</label>
                                            <InputField id={"username-input"} type={"text"} className={this.state.inputUsernameClass} aria-describedby="emailHelp" placeholder={"Syötä käyttäjänimi"} onChange={this.handleOnUsernameChange} />
                                        </div>
                                        <div id="dropdown-div1" className="form-group">
                                            <label id="dropdown-label0" htmlFor="exampleInputEmail1">Sähköposti</label>
                                            <InputField id={"email-input"} type={"email"} className={this.state.inputEmailClass} aria-describedby="emailHelp" placeholder={"Syötä sähköposti"} onChange={this.handleOnEmailChange} />
                                        </div>
                                        <div id="dropdown-div2" className="form-group">
                                            <label id="dropdown-label1" htmlFor="exampleInputPassword1">Salasana</label>
                                            <InputField id={"password-input0"} type={"password"} className={this.state.inputPwClass} placeholder={"Salasana"} onChange={this.handleOnPasswordChange} />
                                        </div>
                                        <div id="dropdown-div2" className="form-group">
                                            <label id="dropdown-label1" htmlFor="exampleInputPassword1">Vahvista salasana</label>
                                            <InputField id={"password-input1"} type={"password"} className={this.state.inputPwClass} placeholder={"Vahvista salasana"} onChange={this.handleOnPasswordConfirmationChange} />
                                        </div>
                                        <div id="dropdown-div3" className="row">
                                            <SubmitButton id={"dropdown-button2"} className={"ml-3 btn btn-primary"} text={"Takaisin"} onClick={this.handleBackClick} />
                                            <SubmitButton id={"dropdown-button2"} className={"ml-2 btn btn-primary"} text={"Lähetä"} onClick={this.handleSubmitClick}/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    );
                }
            }
        }
    }
}

export default observer(LoginForm);