import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import '.././css/sidebar.css';

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            kohteet: [],
            keyword: "",
            paikkakunta: "",
            palvelut: "",
            palvelubox: null,
            paikkakuntabox: null
        };
        
       }
       

    componentDidMount = () => {

    }

    searchAll = (e) => {
        e.preventDefault();
        if(this.refs.locationEnabled.checked) {
            this.props.search(this.state.keyword, true, this.state.paikkakunta, this.state.palvelut);
        }
        else {
            this.props.search(this.state.keyword, false, this.state.paikkakunta, this.state.palvelut);
        }
        this.setState({paikkakunta: "", palvelut: ""});
    }

    search = (e) => {
        this.setState({keyword: e.target.value});
    }

    searchAccurateChange = (e) => {
        this.setState({paikkakunta: e.target.value});

    }
    searchAccurateChange2 = (e) => {
        this.setState({palvelut: e.target.value});

    }
    
    render() {
        return (
            <div className="p-2 collapse in" id="sidebar">
                <div className="list-group panel" id="sidebar-div0">
                    <form id="sidebar-form0">
                        <div id="sidebar-div01" className="form-group active">
                            <label id="sidebar-label0" htmlFor="exampleInputEmail1">Kohteen nimi</label><span> </span>
                            <FontAwesomeIcon icon={faInfoCircle} className="pointer dropdown-toggle" data-toggle="dropdown" alt="Hakuohjeet" />
                            <div id="search-info" className="p-2 dropdown-menu dropdown-menu-right">
                                <p>Hae kirjoittamalla hakusana<br/>
                                alla mainituissa muodoissa</p>
                                Esim.
                                <ul>
                                    <li>kuntosali, jyväskylä</li>
                                    <li>nuotiopaikka</li>
                                    <li>kuopio</li>
                                </ul>
                            </div>
                            <div className="row">
                                <div className="col-7 ml-3 p-0">
                                    <input id='search-by-keyword' type="text" className="form-control" onChange={ this.search } aria-describedby="emailHelp" placeholder="kuntosali, jyväskylä"/>
                                </div>
                                <button id="sidebar-button0" href="#sidebar" data-toggle="collapse" type="submit" className="btn btn-primary ml-2 col-3" onClick={this.searchAll}>Haku</button>
                            </div>
                            <div id="searchlocationBox" className="row pl-3">
                                <input id="searchNearMyLocation" ref="locationEnabled"  className="mt-2 ml-2" type="checkbox" aria-label="Checkbox for finding places near my location"/>
                                <label  id="searchNearMyLocationLabel">Etsi läheltä sijaintiani (50 km)</label>
                                <FontAwesomeIcon icon={faInfoCircle} className="pointer dropdown-toggle" data-toggle="dropdown" alt="Sijaintiohjeet" />
                                <div id="location-info" className="p-2 dropdown-menu dropdown-menu-right">
                                <p>Vaatii sijaintipalveluiden käytön</p>
                                <p>Valitse => Paina Haku</p>
                            </div>
                            </div>
                        </div>
                    </form>
                    <div id="sidebar-div02" className="dropdown">
                        <span id="sidebar-button1" className="btn btn-light dropdown-toggle p-0 thaku" type="button" data-toggle="dropdown">Tarkennettu haku
                        <span id="sidebar-span0" className="caret"></span></span>
                        <div id="sidebar-div020" className="p-0 dropdown-menu border-0 mt-2 w-100 h-100">
                            <form id="sidebar-form1">
                                <div id="sidebar-div0200" className="form-group p-0">
                                    <label id="sidebar-label1" htmlFor="exampleInputEmail1">Paikkakunta</label>
                                    <input onChange={this.searchAccurateChange} id="sidebar-input1" type="text" className="form-control" placeholder="Syötä paikkakunta" value={this.state.paikkakunta} />
                                </div>
                                <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <label className="input-group-text" htmlFor="inputGroupSelect01">Palvelut</label>
                                </div>
                                    <select onChange={this.searchAccurateChange2}  className="custom-select" id="inputGroupSelect01" value={this.state.palvelut}>
                                        <option value=""> Valitse...</option>
                                        <option value="ampuma">Ampumaurheilu</option>
                                        <option value="golf">Golf/Frisbee</option>
                                        <option value="hyppyri">Hyppyrimäet</option>
                                        <option value="huolto">Huoltorakennukset</option>
                                        <option value="ilma">Ilmailu</option>
                                        <option value="luistelu">Jääurheilu</option>
                                        <option value="keila">Keilahallit</option>
                                        <option value="kenttä">Kentät</option>
                                        <option value="kiipeily">Kiipeily</option>
                                        <option value="koira">Koiraurheilu</option>
                                        <option value="halli">Liikuntahallit</option>
                                        <option value="maasto">Maastoliikunta</option>
                                        <option value="moottori">Moottoriurheilu</option>
                                        <option value="pallo">Palloilu</option>
                                        <option value="ratsastus">Ratsastus</option>
                                        <option value="retkeily">Retkeily</option>
                                        <option value="sali">Salit/Sisäliikunta</option>
                                        <option value="suunnistus">Suunnistus</option>
                                        <option value="ranta">Uimarannat</option>
                                        <option value="vene">Veneurheilu</option>
                                        <option value="uima">Vesiliikunta</option>
                                        <option value="yleisurheilu">Yleisurheilu</option>
                                    </select>
                                </div>
                                <div  id="dropdown-div3" className="row p-0">
                                    <button id="sidebar-dropdown-button0" href="#sidebar" data-toggle="collapse"  type="submit" className="ml-3 btn btn-primary" onClick={this.searchAll}>Tarkennettu haku</button>
                                </div>
                                <span style={{zIndex:999, position:"relative", left:10,top:20}}><strong>@KRAKEN 2020</strong> </span>
                                
                            </form>
                            
                        </div>
                        
                    </div>
                    
                </div>
                
                
        </div>
        
        );
        
    }
    
}

export default Sidebar;